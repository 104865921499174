import { RoleAllowedToLogInAsClient } from "@silexpert/core";

export function useFeatureComposable() {
  const roleComposable = useRolesComposable();
  const brandComposable = useBrandsComposable();
  const prestationComposable = usePrestationsComposable();

  function canManageExercice() {
    if (
      brandComposable.isComptaStart() &&
      !prestationComposable.hasSignedPaidAccountingPrestation()
    ) {
      return true;
    }

    if (brandComposable.isComptalib() || brandComposable.isAnytime()) {
      return true;
    }

    return roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient);
  }

  return { canManageExercice };
}
